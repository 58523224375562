// Git Id: 13  Developer: Pankaj Motwani  Date: 06/01/2025
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DIALOG_DATA, MatDialogRef, MatTableDataSource } from '@angular/material';
import { IndianDateadapterService, MY_DATE_FORMATS } from '../../../common-services/indian-dateadapter.service';
import { SharedService } from '../../../shared/shared.service';
import { DateService } from '../../../common-services/common-functions.service';
import { SnackBarService } from '../../../common-services/snack-bar.service';
import { isNullOrUndefined } from 'util';
import { Router } from '@angular/router';

@Component({
  selector: 'app-update-po-status',
  templateUrl: './update-po-status.component.html',
  providers: [
    { provide: DateAdapter, useClass: IndianDateadapterService },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ],
})
export class UpdatePoStatusComponent implements OnInit {
  storeCode: string;
  updatepostatusform: FormGroup;
  selectedPurchaseOrder: any;
  controldisable: boolean = false;
  entrydisable: boolean = false;
  poHeader: any;
  poItemDetail: any;
  reason: string;
  Id: number = 0;
  pageProperties = {
    paramId: 0
 };

 tableObject = {
    displayedColumns: ['code', 'name', 'genericName', 'qty', 'uomCode', 'mM_PackSize', 'tentativeRate', 'subTotal', 'mm_TaxPercent','taxAmount', 'totalAmount'],
    headerNames: ['Product Code', 'Product Name', 'Generic Name', 'Quantity', 'Uom Code', 'Pack Size','Tentative Rate', 'Sub Total', 'Tax %', 'Tax Amount','Total Amount']
  };

  updatepostatusdataSource = new MatTableDataSource<any>([]);

  constructor(private fg: FormBuilder,
    private notification: SnackBarService,
    private sharedService: SharedService,
    public dialogReff: MatDialogRef<UpdatePoStatusComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.selectedPurchaseOrder = data.header;
      this.updatepostatusdataSource = new MatTableDataSource<any>();
  }

  ngOnInit() {
    this.updatepostatusform = this.fg.group({ Id: [0], poNumber: [''], vendorCode: [''], poDate: [''], vendorName: [''] });
    this.bindForm(null);
    this.sharedService.customGetApi('purchase/getPoDetails?id=' + this.selectedPurchaseOrder.id).subscribe((x: any) => {
        if (x.status == true) {
          this.poHeader = x.data.poHeader[0];
          this.poItemDetail = x.data.poItemDetail;
          this.storeCode = x.data.poHeader[0].storeCode;

          this.reason = this.poHeader.po_Reason;
          if (!isNullOrUndefined(this.poHeader.po_cposPoStatus)){
            this.entrydisable = true;
          }
        }

        this.updatepostatusform.setValue({
          Id: this.poHeader.id,
          poNumber: this.poHeader.poNo,
          vendorCode: this.poHeader.vendorCode,
          poDate: DateService.formatDate(this.poHeader.poDate,'DD/MM/YYYY'),
          vendorName: this.poHeader.storeName
        });

        this.updatepostatusdataSource.data = this.poItemDetail;
      });
    }

  bindForm(selectData: any) {
    try {
      this.updatepostatusform = this.fg.group({
        'Id': [(selectData ? selectData.Id : null)],
        'poNumber': [(selectData ? selectData.poNumber : null), null],
        'vendorCode': [(selectData ? selectData.vendorCode : null), null],
        'poDate': [(selectData ? new Date(new Date(selectData.poDate)) : null)],
        'vendorName': [(selectData ? selectData.vendorName : null)]
      });
    } catch (error) {
      console.log(error);
    }
  }

  onCancelClick(){
    this.dialogReff.close();
  }


  onApproveReject(val){
    if (val == 0 && (this.reason == '' || isNullOrUndefined(this.reason))){
      this.notification.error("Reject Reason Is Compulsory!")
      return;
    }

    const filterdata = {id: this.updatepostatusform.getRawValue().Id, po_cposPoStatus: val, po_Reason: this.reason }
    this.sharedService.customPostApi('purchase/updatePoStatus', filterdata).subscribe((t) => {
      this.notification.success('Purchase Order Status Updated Successfully!');
      this.dialogReff.close();
    }, (error) => {
      this.notification.success('Purchase Order Status Updated failed');
    });
  }
}
